<template>
    <v-app>
        <v-main>
           
            <Skeletons v-if="$store.state.dataUser.permisos_usuario==true" :cantidad="5"  />
            <v-row v-else>
                <v-col cols="12" sm="6" v-for="(permiso,index) in $store.state.dataUser.permisos_usuario" :key="index">
                  
                     <CardPermisos 
                        :icon="permiso.permiso.icon" 
                        :nombre="permiso.permiso.nombre" 
                        :imagen="permiso.permiso.imagen"
                        :redireccion="permiso.permiso.ruta"
                    />
                </v-col>
            </v-row>
        </v-main>
    </v-app>
</template>
<script>
import Skeletons from '../components/admin/Skeletons'
import CardPermisos from '../components/comunes/CardPermisos'
export default {
    components: {
        Skeletons,
        CardPermisos,
    },
    data(){
        return {

        }
    },
    created(){
        console.log('xd')
    },
    methods: {
        metodo(){
            this.$store.state.hola
        }
    },
}
</script>