<template>
  <v-hover v-slot="{ hover }">
    <v-card
      class="mx-auto"
      color="grey lighten-1"
      max-width="600"
      elevation="4"
      min-height="250"
      @click="$router.push(redireccion)"
    >
      <v-img
        :aspect-ratio="16/9"
        :src="imagen"
      >
        <v-expand-transition>
          <div
            v-if="hover"
            class="d-flex transition-fast-in-fast-out indigo darken-2 v-card--reveal display-3 white--text"
            style="height: 100%;"
          >
            {{nombre}}
          </div>
        </v-expand-transition>
      </v-img>
      <v-card-text
        class="pt-6"
        style="position: relative;"
      >
        <v-btn
          absolute
          color="indigo"
          class="white--text"
          fab
          large
          right
          top
          @click="$router.push(redireccion)"
        >
          <v-icon>{{icon}}</v-icon>
        </v-btn>
        
        <h3 class="display-1 font-weight-light indigo--text mb-2">
          {{nombre}}
          
        </h3>
        
        
      </v-card-text>
    </v-card>
  </v-hover>
</template>
<script>
export default {
    props: {
        imagen: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            default: 'mdi-home'
        },
       nombre: {
            type: String,
        },
        redireccion: {
            type: Object
        },
    },
    //props: ['imagen','texto','icon','descripcion','nombre','redireccion'],
    data(){
        return{

        }
    },
    methods: {

    },

}
</script>
<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .8;
  position: absolute;
  width: 100%;
}
</style>

  
